import React from 'react';



const Policies = () => {
    return (
        <>
            <div style={{ textAlign: 'left', margin: '40px' }}>
                <h2>Policies for Sugar Paws Pet Salon</h2>
                <p><b>Thank you for choosing Sugar Paws Pet Salon for your pet grooming needs. Please take a moment to review our policies outlined below:</b>
                    <br />
                    <br />
                    <b>Deposit Required: </b>
                    All new clients must pay a deposit of 50% of service requested in order to secure appointment. Which will go towards your appointment the day of. Non-refundable unless canceled with 48
                    hour notice or more.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Card on file: </b>
                    All Clients must save an active card in file which will be stored securely and agree by a signed waiver.

                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Cancellation / No Shows: </b>
                    Clients must provide a minimum of 48 hours' notice for any appointment cancellations. Failure to do so will result in a charge equivalent to 50% of the service fee.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Matting Policy: </b>
                    Severe matting takes extra time to safely remove and it will require an extra charge.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Late Dropoffs: </b>
                    Clients arriving more than 10 minutes past their scheduled drop-off time will be considered a no-show and will be subject to a charge equivalent to 50% of the service fee.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Pickup Policy: </b>
                    Clients are expected to pick up their pets promptly at the scheduled time. A fee of $1 per minute will be charged for any delays beyond the scheduled pickup time. This is to ensure I can stay focused with no interruptions with the pet I have on my table.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Booking Schedule: </b>
                    All dog grooming appointments must be scheduled within 4 to 10 weeks from the current date. Appointments scheduled beyond 10 weeks may be subject to an additional fee, depending on the duration of the appointment and service requested.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Sedated Dogs: </b>
                    We do not accept dogs under sedation unless prior arrangements have been discussed and approved.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Vaccination Requirements:</b>
                    Clients are responsible for ensuring that their dogs are fully vaccinated according to veterinary recommendations.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Vet Clearance: </b>
                    Any dog that has undergone a recent sick or surgical visit with a veterinarian must be cleared by the veterinarian before grooming services can be provided.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />

                    <b>Right to Refuse Services: </b>
                    We reserve the right to refuse grooming services for dogs that are severely matted, aggressive, or displaying signs of illness.
                    <br />
                    <br />
                    <div style={{ borderBottom: '2px solid black', marginBottom: '10px' }} />
                    <br />

                    <b>Thank you for your cooperation and understanding. If you have any questions or concerns regarding our policies, please feel free to <a href='./location'>Contact Us.</a></b></p>
            </div>
        </>
    );
};



export default Policies;
