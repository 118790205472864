import React from 'react';
import Logo from '../assets/CircleLogo.png';
import '../styles/home.css';


const About = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src={Logo} alt="Logo" style={styles.logo} />
                <h1 style={styles.header}>Message From The Owner</h1>
                <p style={styles.missionStatement}>
                    Hi there, I'm Alexandra! With more than a decade of experience in pet grooming, animals are truly my passion! I am a proud owner of 2 dogs, 5 cats and 1 adorable bunny! Outside of work, I'm a dedicated mom to my wonderful young son, and I'm lucky to have a supportive and involved husband, Dominic.                </p>
            </header>

        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
    },
    header: {
        fontSize: '28px',
        marginBottom: '10px',
        color: 'black', // Ensure header text is white
    },
    logo: {
        width: '90%',
        maxWidth: '400px',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '8px',
        marginBottom: '-20px',
        padding: '20px',
    },
    logows: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        marginTop: '40px',
    },
    missionStatement: {
        fontSize: '18px',
        maxWidth: '900px',
        margin: '20px auto',
        textAlign: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    contentImage: {
        width: '300px',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: 'auto',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box', // Include padding and border in the box's width
        marginTop: '-4px',
        padding: '20px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'black',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'center',
        fontSize: '26px',
        marginTop: '5px'
    },
    itemWrapper: {
        backgroundColor: '#6b4b21',
        height: '100%',
        borderRadius: '8px',

    },
    itemWrapper2: {
        backgroundColor: 'transparent',
        height: '100%',
        borderRadius: '8px',

    },
    sectionHeading2: {
        color: 'black',
        textAlign: 'center',
        fontSize: '26px',
        marginTop: '5px'
    },
    secImg: {
        height: '200px',
        width: 'auto'
    },
    container2: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '30px'
    },
};

export default About;
