import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import Logo from '../assets/CircleLogo.png';
import on1 from '../assets/1on1.jpeg';
import noCage from '../assets/noCage.png';
import Qual from '../assets/Qual.png';
import '../styles/home.css'


const Home = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src={Logo} alt="Logo" style={styles.logo} />
                <p style={styles.missionStatement}>
                    Welcome to Sugar Paws Pet Salon, where your pets comfort comes first!  🐾
                    <br />
                    At Sugar Paws, we specialize in 1-on-1 grooming, ensuring that your pet receives our undivided attention from start to finish. Say goodbye to cages! Our cage-free environment is designed to provide a stress-free experience for your pet, making grooming a great experience for everyone!
                </p>
            </header>
            <div style={{ textAlign: 'center', marginBottom: '40px', marginTop: '-10px' }}>
                <Button
                    component={RouterLink}
                    to="/services"
                    sx={{
                        color: 'black',
                        border: '2px solid black', // Corrected border property
                        width: '200px',
                        height: '100%',
                        '&:hover': {
                            border: '2px solid transparent', // Corrected border property
                            backgroundColor: '#599a89', // Apply gold background on hover
                            color: 'white',
                            height: '100%',
                        },
                    }}
                >
                    Services
                </Button>
            </div>



            <div style={styles.container}>
                <Grid container spacing={2} justifyContent="center">
                    {/* Repeat for each item */}
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Care</h2>
                                <p>Where every pet gets the VIP treatment! We specialize in providing tailored, affectionate care! Your pet isn't just another client to us. They're a beloved member of your family, and we treat them as such.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Clean</h2>
                                <p>Our salon prides itself on impeccable hygiene, using high-end products for a great finish that's as safe as it is sparkling! Your pet's health and safety are our top priorities, so you can trust that they'll leave our salon looking and feeling their absolute best!</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Style</h2>
                                <p>Step into a world of personalized style at Sugar Paws Pet Salon! We believe grooming is an art form! An opportunity to showcase your pet's unique personality through their style. Indulge your pet in a pampering experience like no other!</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={styles.container2}>
                <Grid container spacing={2} justifyContent="center">
                    {/* Repeat for each item */}
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper2}>
                            <div style={styles.textContent}>
                                <img style={styles.secImg} src={on1} />
                                <h2 style={styles.sectionHeading2}>1 On 1 Grooming</h2>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper2}>
                            <div style={styles.textContent}>
                                <img style={styles.secImg} src={noCage} />

                                <h2 style={styles.sectionHeading2}>Cage Free</h2>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper2}>
                            <div style={styles.textContent}>
                                <img style={styles.secImg} src={Qual} />

                                <h2 style={styles.sectionHeading2}>High Quality Products</h2>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>


            <div className="news-container">
                <div className="news-item">
                    <h2>Specialties</h2>
                    <p>Sugar Paws Pet Salon provides patient and gentle 1 on 1 grooming services, utilizing high-end products tailored for skincare and pet comfort. Our specialty lies in treating your pet with the same care and attention as our own furry family members.</p>
                </div>

            </div>

            {/* Add more sections as needed */}
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
    },
    header: {
        padding: '20px',
    },
    logo: {
        width: '90%',
        maxWidth: '400px',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '8px',
        marginBottom: '-20px',
    },
    logows: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        marginTop: '40px',
    },
    missionStatement: {
        fontSize: '18px',
        maxWidth: '900px',
        margin: '20px auto',
        textAlign: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    contentImage: {
        width: '300px',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: 'auto',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box', // Include padding and border in the box's width
        marginTop: '-4px',
        padding: '20px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'black',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'center',
        fontSize: '26px',
        marginTop: '5px'
    },
    itemWrapper: {
        backgroundColor: '#6b4b21',
        height: '100%',
        borderRadius: '8px',

    },
    itemWrapper2: {
        backgroundColor: 'transparent',
        height: '100%',
        borderRadius: '8px',

    },
    sectionHeading2: {
        color: 'black',
        textAlign: 'center',
        fontSize: '26px',
        marginTop: '5px'
    },
    secImg: {
        height: '200px',
        width: 'auto'
    },
    container2: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '30px'
    },
};

export default Home;
