import React from 'react';
import { Grid, Button } from '@mui/material';
import Logo from '../assets/CircleLogo.png' // Assume this is a logo related to dog grooming
import Tidy from '../assets/Tidy.png' // Assume this is a logo related to dog grooming
import Bath from '../assets/Bath.png' // Assume this is a logo related to dog grooming
import Haircut from '../assets/Haircut.png' // Assume this is a logo related to dog grooming
import Ala from '../assets/Ala.png' // Assume this is a logo related to dog grooming
import Add from '../assets/Add.png' // Assume this is a logo related to dog grooming
import { Link as RouterLink } from 'react-router-dom';
import '../styles/service.css'

const Services = () => {
    return (
        <div style={styles.container}>
            <Grid container spacing={2} justifyContent="center">
                {/* Repeat for each item */}
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Haircut} alt="Dog Grooming Service" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Haircut</h2>
                            <p style={{ textAlign: 'left', marginBottom: '25px' }}> <b>Includes:</b> Bath, Blow Out, Brush Out, Ear Cleaning, Nail Buffing, Teeth Spray, Scent, Bow/Bandanna</p>

                            <div class="price-section">
                                <p>Small:</p>
                                <p>$115-$135+</p>
                            </div>
                            <div class="price-section">
                                <p>Medium:</p>
                                <p>$135-$160+</p>
                            </div>
                            <div class="price-section">
                                <p>Large:</p>
                                <p>$160-$195+</p>
                            </div>
                            <div class="price-section">
                                <p>Extra Large:</p>
                                <p>$195-$220+</p>
                            </div>
                            <div class="price-section">
                                <p>Standard Poodles/Doodles:</p>
                                <p>$200+</p>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    component={RouterLink}
                                    to="/booking"
                                    sx={{
                                        color: 'black',
                                        border: '2px solid black',
                                        width: '60%',
                                        '&:hover': {
                                            backgroundColor: '#599a89', // Apply gold background on hover
                                            color: 'white',
                                            border: '2px solid transparent',

                                        },
                                    }}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Bath} alt="Dog Grooming Service" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Bath</h2>
                            <p style={{ textAlign: 'left', marginBottom: '25px' }}> <b>Includes:</b> Bath, Blow Out, Brush Out, Ear Cleaning, Nail Buffing, Teeth Spray, Scent, Bow/Bandanna</p>

                            <div class="price-section">
                                <p>Small:</p>
                                <p>$70-$80+</p>
                            </div>
                            <div class="price-section">
                                <p>Medium:</p>
                                <p>$80-$100+</p>
                            </div>
                            <div class="price-section">
                                <p>Large:</p>
                                <p>$100-$120+</p>
                            </div>
                            <div class="price-section">
                                <p>Extra Large:</p>
                                <p>$120-$140+</p>
                            </div>
                            <div class="price-section">
                                <p>Cat Baths:</p>
                                <p>$120-$160+</p>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    component={RouterLink}
                                    to="/booking"
                                    sx={{
                                        color: 'black',
                                        border: '2px solid black',
                                        width: '60%',
                                        '&:hover': {
                                            backgroundColor: '#599a89', // Apply gold background on hover
                                            color: 'white',
                                            border: '2px solid transparent',

                                        },
                                    }}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Ala} alt="Dog Grooming Service" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>A La Carte</h2>
                            <div class="price-section">
                                <p>Nail Buffing:</p>
                                <p>$35-$55+</p>
                            </div>
                            <div class="price-section">
                                <p>Anal Glands (External ONLY):</p>
                                <p>$30</p>
                            </div>
                            <div class="price-section">
                                <p>Teeth Brushing/Spray:</p>
                                <p>$15</p>
                            </div>
                            <div class="price-section">
                                <p>Ear Cleaning:</p>
                                <p>$15</p>
                            </div>
                            <div class="price-section">
                                <p>Ear Hair Plucking/Shave:</p>
                                <p>$20</p>
                            </div>
                            <div class="price-section">
                                <p>Feet Trimming:</p>
                                <p>$20</p>
                            </div>
                            <div class="price-section">
                                <p>Eye Trimming:</p>
                                <p>$15</p>
                            </div>
                            <div class="price-section">
                                <p>Sanitary Trim:</p>
                                <p>$15</p>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    component={RouterLink}
                                    to="/booking"
                                    sx={{
                                        color: 'black',
                                        border: '2px solid black',
                                        width: '60%',
                                        '&:hover': {
                                            backgroundColor: '#599a89', // Apply gold background on hover
                                            color: 'white',
                                            border: '2px solid transparent',

                                        },
                                    }}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Tidy} alt="Dog Grooming Service" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Tidy Up</h2>
                            <p style={{ textAlign: 'center', marginBottom: '-10px' }}><b>Face, Feet and Sanitary Trim</b></p>
                            <p style={{ textAlign: 'center', marginBottom: '25px' }}>$20 Plus Bath Price</p>
                            <p style={{ textAlign: 'center', marginBottom: '-10px' }}><b>Outline</b></p>
                            <p style={{ textAlign: 'center', marginBottom: '30px' }}>$35 Plus Bath Price</p>
                            <p style={{ textAlign: 'center', marginBottom: '25px', fontSize: '13px' }}><b>Shape all over whatever is sticking out:</b> <br />Legs, Feet, Sanitary, Face, and Undercarriage <br /> (Length on body stays the same)</p>



                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    component={RouterLink}
                                    to="/booking"
                                    sx={{
                                        color: 'black',
                                        border: '2px solid black',
                                        width: '60%',
                                        '&:hover': {
                                            backgroundColor: '#599a89', // Apply gold background on hover
                                            color: 'white',
                                            border: '2px solid transparent',

                                        },
                                    }}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Add} alt="Dog Grooming Service" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Add Ons</h2>
                            <div class="price-section">
                                <p>Medicated Bath:</p>
                                <p>$15</p>
                            </div>
                            <div class="price-section">
                                <p>Flea Bath:</p>
                                <p>$15</p>
                            </div>
                            <div class="price-section">
                                <p>Shedless Treatment:</p>
                                <p>$25-45+</p>
                            </div>
                            <p style={{ textAlign: 'center', marginBottom: '25px', fontSize: '13px' }}><b>Add on with Haircut/Bath Packages</b> </p>

                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    component={RouterLink}
                                    to="/booking"
                                    sx={{
                                        color: 'black',
                                        border: '2px solid black',
                                        width: '60%',
                                        '&:hover': {
                                            backgroundColor: '#599a89', // Apply gold background on hover
                                            color: 'white',
                                            border: '2px solid transparent',

                                        },
                                    }}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'left',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    itemWrapper: {
        maxWidth: '320px',
        width: '100%',
        margin: '0 auto',
    },
    contentImage: {
        width: '100%',
        height: '300px',
        objectFit: 'cover',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box', // Include padding and border in the box's width
        border: '3px solid #599a89',
        borderTop: '0px',
        marginTop: '-4px',
        padding: '20px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        color: 'black',
        backgroundColor: 'transparent',

    },
    sectionHeading: {
        color: 'black',
        textAlign: 'Center',
        fontSize: '26px',
    },

};

export default Services;
