import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import Logo from '../assets/CircleLogo.png';


const Test = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src={Logo} alt="Logo" style={styles.logo} />
                <p style={styles.missionStatement}>
                    Welcome to Sugar Paws, where every pet is treated like family. At Sugar Paws Pet Salon, we're all about giving your furry friends the best grooming experience. We believe every pet deserves to be pampered with the best.
                </p>
            </header>
            <div style={{ textAlign: 'center', marginBottom: '40px', marginTop: '-20px' }}>
                <Button
                    component={RouterLink}
                    to="/services"
                    sx={{
                        color: 'black',
                        border: '2px solid black', // Corrected border property
                        width: '200px',
                        '&:hover': {
                            backgroundColor: 'silver', // Apply gold background on hover
                            color: 'white',
                        },
                    }}
                >
                    Services
                </Button>
            </div>



            <div style={styles.container}>
                <Grid container spacing={2} justifyContent="center">
                    {/* Repeat for each item */}
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Care</h2>
                                <p>Where every pet gets the VIP treatment. At Sugar Paws, we specialize in providing tailored, affectionate care that speaks directly to the heart of your furry family member.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Clean</h2>
                                <p>Pristine grooming, healthy pets. Our salon prides itself on impeccable hygiene, using eco-friendly products for a clean that's as safe as it is sparkling.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>Style</h2>
                                <p>Stylish pets, happy hearts. At Sugar Paws, we blend grooming artistry with your pet's unique personality for a look that's as charming as it is chic.</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/* Add more sections as needed */}
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
    },
    header: {
        padding: '20px',
    },
    logo: {
        width: '85%',
        maxWidth: '350px',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '8px',
    },
    logows: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        marginTop: '40px',
    },
    missionStatement: {
        fontSize: '18px',
        maxWidth: '900px',
        margin: '20px auto',
        textAlign: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    contentImage: {
        width: '300px',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: 'auto',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box', // Include padding and border in the box's width
        marginTop: '-4px',
        padding: '20px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'black',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'center',
        fontSize: '26px',
        marginTop: '5px'
    },
    itemWrapper: {
        backgroundColor: 'silver',
        height: '100%',
        borderRadius: '8px',

    }
};

export default Test;
