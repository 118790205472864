import React from 'react';
import Logo from '../assets/CircleLogo.png';

const address = '6982 Village Parkway, Dublin CA 94568';

const openLocationInMaps = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(mapsUrl, '_blank');
};

const Booking = () => {
    return (
        <div style={styles.container}>


            <h1 style={styles.header}>Location</h1>
            <br />
            <a onClick={openLocationInMaps} style={styles.link}>{address}</a>
            <br />


            <h1 style={styles.header}>Hours</h1>
            <br />
            <div style={styles.textContent}>

                <div class="price-section">
                    <p>Monday</p>
                    <p style={{ marginLeft: '20px' }}>By Appointment Only</p>
                </div>
                <div class="price-section">
                    <p>Tuesday</p>
                    <p style={{ marginLeft: '20px' }}>By Appointment Only</p>
                </div>
                <div class="price-section">
                    <p>Wednesday</p>
                    <p style={{ marginLeft: '20px' }}>By Appointment Only</p>
                </div>
                <div class="price-section">
                    <p>Thursday</p>
                    <p style={{ marginLeft: '20px' }}>By Appointment Only</p>
                </div>
                <div class="price-section">
                    <p>Friday</p>
                    <p style={{ marginLeft: '20px' }}>Closed</p>
                </div>
                <div class="price-section">
                    <p>Saturday</p>
                    <p style={{ marginLeft: '20px' }}>By Appointment Only</p>
                </div>
                <div class="price-section">
                    <p>Sunday</p>
                    <p style={{ marginLeft: '20px' }}>Closed</p>
                </div>
                <br />
                <h1 style={styles.header}>Contact</h1>
                <p style={styles.text}>The best way to reach us is by <b>TEXT</b></p>
                <br />

                <a href="tel:5109092375" style={styles.link}>+1 (510) 909-2375</a>
                <br />

                <a href="mailto:SugarPaws@gmail.com" style={styles.link}>SugarPawsPetSalon@yahoo.com</a>
                <br />
            </div>
        </div>
    );
};

const styles = {
    container: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align items to the left
        color: 'black', // Set text color to white
        padding: '60px',
        textAlign: 'left',
        borderRadius: '8px'
    },
    header: {
        fontSize: '28px',
        marginBottom: '10px',
        color: 'black', // Ensure header text is white
    },
    text: {
        fontSize: '18px',
        marginBottom: '10px',
        color: 'black', // Ensure text is white
    },
    link: {
        fontSize: '20px',
        color: 'black', // Set links to white or any other color you prefer
        textDecoration: 'underline',
        cursor: 'pointer',
        marginBottom: '10px', // Added for spacing between links
    },
};

export default Booking;
