import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
// Import other components
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Services from './pages/Services';
import Booking from './pages/Booking';
import Test from './pages/Test';
import Policies from './pages/Policies';
import ContactLocation from './pages/ContactLocation';
import About from './pages/About';


function App() {
  return (
    <>
      <Router>
        <div className="appContainer">
          <ScrollToTop />
          <Navbar />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/test" element={<Test />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/location" element={<ContactLocation />} />
              <Route path="/about" element={<About />} />

            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
