import React from 'react';

const Footer = () => {
    return (
        <div style={styles.footerContainer}>
            <p style={styles.footerText}>
                © Sugar Paws 2023
            </p>
        </div>
    );
};

const styles = {
    footerContainer: {
        textAlign: 'center',
        marginTop: 'auto', // Pushes the footer to the bottom
        backgroundColor: 'transparent', // or any color you prefer
        color: 'black',
        padding: '18px 0', // Adjust padding as needed
        width: '100%',
    },
    footerText: {
        margin: 0, // Remove default margin
    },
};

export default Footer;
