import React from 'react';

const BookingW = () => {
    const iframeStyle = {
        width: '100%',
        height: '800px',
    };

    return (
        <div style={iframeStyle}>
            <iframe
                src="https://booking.moego.pet/ol/book?name=SugarPawsPetSalon&utm_medium=embed"
                width="100%"
                height="100%"
                frameBorder="0"
                title="Online booking"
                scrolling="no"
            ></iframe>
        </div>
    );
};

export default BookingW;
